@font-face {
  font-family: "Inter";
  src: local("Inter"), url(../fonts/Inter-Medium.ttf) format("woff");
  font-display: swap;
}

* {
  font-family: "Inter";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  color-scheme: only light;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 12px;
  background: #f6f7fb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #d8dde8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c6cbd4;
}

.ant-input-outlined {
  border: none;
  border-bottom: 2px solid #b8c1cb;
  border-radius: 0;
  width: 45px;
  font-size: 1.3rem;

  &:focus {
    box-shadow: none;
    border-color: #005984;
  }

  &:hover {
    border-color: #b8c1cb;
  }
}

/* .ant-input,
.ant-select,
.ant-select-focused,
.ant-select-selector,
.ant-select-focused {
  &:focus {
    border: none;
    border-bottom: 1.5px solid #005984;
  }
} */

.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.ant-tabs-nav:before {
  /* border-bottom: none !important; */
}

.ant-tabs-content,
.ant-tabs-tabpane {
  height: 100%;
}

.ant-tabs-content-holder {
  border: none !important;
}

.ant-select-arrow {
  color: #F98315 !important
}

.ant-picker-suffix {
  color: #F98315 !important
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

/* .slick-prev {
  left: 40px !important;
} */